// App.jsx
import React from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import "../css/Body.css";
import { FaPenToSquare, FaTrashCan } from 'react-icons/fa6';
import { profile } from '../api/url';
import axiosInstance from '../api/axiosinstance';
import WithToast from '../context/withtoast';

const Dashboard = () => {
   
  return (
    <div className="wrppaer">
        <div className="admin_panel_section">
            <div className="c_full">
                <div className="row">
                    <Sidebar />
                    <div className="col_80 he__con col">
                    <Header />
                        <div className="body_section">
                            <div className="card">
                                <div className="card-body">
                                <h4 className="heading_">Top Products</h4>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col" align="left">Name</th>
                                        <th scope="col">Popularity</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>01</td>
                                        <td align="left">Home Decor Range</td>
                                        <td>Popularity</td>
                                        <td>
                                        <a href="edit.php" className='edit_icon'><FaPenToSquare /></a>
                                        <a href="" className='fa-trash-can'><FaTrashCan /></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>02</td>
                                        <td align="left">Disney Princess Dress</td>
                                        <td>Popularity</td>
                                        <td>
                                        <a href="edit.php" className='edit_icon'><FaPenToSquare /></a>
                                        <a href="" className='fa-trash-can'><FaTrashCan /></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>03</td>
                                        <td align="left">Bathroom Essentials</td>
                                        <td>Popularity</td>
                                        <td>
                                        <a href="edit.php" className='edit_icon'><FaPenToSquare /></a>
                                        <a href="" className='fa-trash-can'><FaTrashCan /></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>04</td>
                                        <td align="left">Apple Smartwatch</td>
                                        <td>Popularity</td>
                                        <td>
                                        <a href="edit.php" className='edit_icon'><FaPenToSquare /></a>
                                        <a href="" className='fa-trash-can'><FaTrashCan /></a>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col" align="left">Name</th>
                                        <th scope="col">Popularity</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>01</td>
                                        <td align="left">Home Decor Range</td>
                                        <td>Popularity</td>
                                        <td>
                                        <a href="edit.php" className='edit_icon'><FaPenToSquare /></a>
                                        <a href="" className='fa-trash-can'><FaTrashCan /></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>02</td>
                                        <td align="left">Disney Princess Dress</td>
                                        <td>Popularity</td>
                                        <td>
                                        <a href="edit.php" className='edit_icon'><FaPenToSquare /></a>
                                        <a href="" className='fa-trash-can'><FaTrashCan /></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>03</td>
                                        <td align="left">Bathroom Essentials</td>
                                        <td>Popularity</td>
                                        <td>
                                        <a href="edit.php" className='edit_icon'><FaPenToSquare /></a>
                                        <a href="" className='fa-trash-can'><FaTrashCan /></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>04</td>
                                        <td align="left">Apple Smartwatch</td>
                                        <td>Popularity</td>
                                        <td>
                                        <a href="edit.php" className='edit_icon'><FaPenToSquare /></a>
                                        <a href="" className='fa-trash-can'><FaTrashCan /></a>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default WithToast(Dashboard);
