// Sidebar.js
import React from 'react';
import "../css/Sidebar.css";
import { Link } from 'react-router-dom';
import { FaBell, FaUser } from 'react-icons/fa'; // Import FontAwesome icons


function Sidebar() {
  return (
    <div className="col_20 pading_to he__con col">
      <div className="menu_slide_bar">
        <Link to="/" className="__logo">
          <img src="https://public.dev.evzone.app/logo/evzone.svg" alt="Logo" />
        </Link>
      </div>
      <div className="lu_namenubar">
        <i className="fa-solid fa-bell icon"></i>
        <ul className="scrollbar">
          {/* <li><Link className="active" to="/"><i className="fa-solid fa-user"></i> Dashboard</Link></li> */}
          {/* <li><Link to="/"><i className="fa-solid fa-user"></i> Dashboard</Link></li>
          <li><Link to="/"><i className="fa-solid fa-user"></i> Dashboard</Link></li>
          <li><Link to="/"><i className="fa-solid fa-user"></i> Dashboard</Link></li>
          <li><Link to="/"><i className="fa-solid fa-user"></i> Dashboard</Link></li>
          <li><Link to="/"><i className="fa-solid fa-user"></i> Dashboard</Link></li>
          <li><Link to="/"><i className="fa-solid fa-user"></i> Dashboard</Link></li> */}
           <li><Link className="active" to="/"><FaUser /> Dashboard</Link></li>
          <li><Link to="/"><FaUser /> Dashboard</Link></li>
          <li><Link to="/"><FaUser /> Dashboard</Link></li>
          <li><Link to="/"><FaUser /> Dashboard</Link></li>
          <li><Link to="/"><FaUser /> Dashboard</Link></li>
          
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
