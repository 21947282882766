// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search_input input {
  background-color: rgb(33, 34, 45);
  padding: 11px 15px 11px 50px;
  font-size: 20px;
  font-weight: 500;
  color: rgb(210, 210, 210);
  border-radius: 10px;
  width: 100%;
}
.search_input {
  position: relative;
}
.search_input i {
  font-size: 18px;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: rgb(210, 210, 210);
}
.bell_icon {
  margin-right: 20px;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}
.profile_img {
  width: 30px;
  cursor: pointer;
}
.arrow_down {
  margin-left: 10px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}
.header_section {
  position: fixed;
  width: 77%;
  background: #171821;
  padding: 30px 0;
}
.pro_pic {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid #484848;
}
.header_p {
  padding-top: 10px;
}
.header_p svg {
  font-size: 20px;
  color: #fff;
  margin: 0 10px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/css/Header.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,4BAA4B;EAC5B,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,2BAA2B;EAC3B,yBAAyB;AAC3B;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;EACX,eAAe;AACjB;AACA;EACE,WAAW;EACX,eAAe;AACjB;AACA;EACE,iBAAiB;EACjB,eAAe;EACf,WAAW;EACX,eAAe;AACjB;AACA;EACE,eAAe;EACf,UAAU;EACV,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,iBAAiB;AACnB;AACA;EACE,eAAe;EACf,WAAW;EACX,cAAc;EACd,eAAe;AACjB","sourcesContent":[".search_input input {\n  background-color: rgb(33, 34, 45);\n  padding: 11px 15px 11px 50px;\n  font-size: 20px;\n  font-weight: 500;\n  color: rgb(210, 210, 210);\n  border-radius: 10px;\n  width: 100%;\n}\n.search_input {\n  position: relative;\n}\n.search_input i {\n  font-size: 18px;\n  position: absolute;\n  left: 20px;\n  top: 50%;\n  transform: translateY(-50%);\n  color: rgb(210, 210, 210);\n}\n.bell_icon {\n  margin-right: 20px;\n  font-size: 24px;\n  color: #fff;\n  cursor: pointer;\n}\n.profile_img {\n  width: 30px;\n  cursor: pointer;\n}\n.arrow_down {\n  margin-left: 10px;\n  font-size: 16px;\n  color: #fff;\n  cursor: pointer;\n}\n.header_section {\n  position: fixed;\n  width: 77%;\n  background: #171821;\n  padding: 30px 0;\n}\n.pro_pic {\n  width: 40px;\n  height: 40px;\n  border-radius: 100%;\n  border: 1px solid #484848;\n}\n.header_p {\n  padding-top: 10px;\n}\n.header_p svg {\n  font-size: 20px;\n  color: #fff;\n  margin: 0 10px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
