function domain()
{
    return process.env.REACT_APP_API_URL;
}

export function publicDomain()
{
    return process.env.REACT_APP_PUBLIC_URL;
}

export function profile()
{
    return domain() + "/api/v1.0/app/core/user/profile";
}

export function getRoles()
{
    return domain() + "/api/v1.0/web/core/admin/roles";
}

export function getModules()
{
    return domain() + "/api/v1.0/web/core/admin/modules";
}

export function getUsers()
{
    return domain() + "/api/v1.0/web/core/admin/users";
}
