// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin_panel_section .pading_to {
  padding-top: 30px !important;
}
.__logo {
  display: inline-block;
}
.__logo img {
  height: 66px;
  margin: auto;
  display: block;
}
img {
  width: 100%;
  max-width: 100%;
}
.admin_panel_section .col.he__con {
  height: 100vh;
}
.lu_namenubar ul li a.active {
  background-color: rgb(169, 223, 216);
  color: rgb(23, 24, 33);
}

.lu_namenubar ul li a {
  padding: 10px 16px;
  font-size: 14px;
  display: flex;
  border-radius: 6px;
  color: rgb(135, 136, 140);
}
.lu_namenubar ul li a:hover {
  background-color: #171821;
}
.lu_namenubar ul li a i {
  padding-right: 15px;
}
.lu_namenubar ul li a svg {
  margin-right: 10px;
}
.lu_namenubar ul {
  margin-top: 88px;
  height: 100vh;
  position: fixed;
  overflow: auto;
  width: 18%;
  padding: 18px;
  background: #21222d;
  border-radius: 5px;
}
.lu_namenubar ul li {
  padding: 0;
  margin-bottom: 20px;
}
.menu_slide_bar {
  display: flex;
  justify-content: center;
  position: fixed;
  left: 6%;
}
.scrollbar::-webkit-scrollbar {
  width: 5px;
}
.scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.scrollbar::-webkit-scrollbar-thumb {
  background: #5f5f5f;
}
`, "",{"version":3,"sources":["webpack://./src/css/Sidebar.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;AACA;EACE,qBAAqB;AACvB;AACA;EACE,YAAY;EACZ,YAAY;EACZ,cAAc;AAChB;AACA;EACE,WAAW;EACX,eAAe;AACjB;AACA;EACE,aAAa;AACf;AACA;EACE,oCAAoC;EACpC,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,kBAAkB;EAClB,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,eAAe;EACf,cAAc;EACd,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,UAAU;EACV,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,QAAQ;AACV;AACA;EACE,UAAU;AACZ;AACA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".admin_panel_section .pading_to {\n  padding-top: 30px !important;\n}\n.__logo {\n  display: inline-block;\n}\n.__logo img {\n  height: 66px;\n  margin: auto;\n  display: block;\n}\nimg {\n  width: 100%;\n  max-width: 100%;\n}\n.admin_panel_section .col.he__con {\n  height: 100vh;\n}\n.lu_namenubar ul li a.active {\n  background-color: rgb(169, 223, 216);\n  color: rgb(23, 24, 33);\n}\n\n.lu_namenubar ul li a {\n  padding: 10px 16px;\n  font-size: 14px;\n  display: flex;\n  border-radius: 6px;\n  color: rgb(135, 136, 140);\n}\n.lu_namenubar ul li a:hover {\n  background-color: #171821;\n}\n.lu_namenubar ul li a i {\n  padding-right: 15px;\n}\n.lu_namenubar ul li a svg {\n  margin-right: 10px;\n}\n.lu_namenubar ul {\n  margin-top: 88px;\n  height: 100vh;\n  position: fixed;\n  overflow: auto;\n  width: 18%;\n  padding: 18px;\n  background: #21222d;\n  border-radius: 5px;\n}\n.lu_namenubar ul li {\n  padding: 0;\n  margin-bottom: 20px;\n}\n.menu_slide_bar {\n  display: flex;\n  justify-content: center;\n  position: fixed;\n  left: 6%;\n}\n.scrollbar::-webkit-scrollbar {\n  width: 5px;\n}\n.scrollbar::-webkit-scrollbar-track {\n  background: #f1f1f1;\n}\n.scrollbar::-webkit-scrollbar-thumb {\n  background: #5f5f5f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
