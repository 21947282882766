import React, { useEffect, useState }  from 'react';
import { useNavigate } from 'react-router-dom';
import "../css/Body.css";
import { FaPenToSquare, FaTrashCan } from 'react-icons/fa6';
import { handleApiError } from '../api/cloudfunction';
import axios from '../instance/axiosInstance';
import WithToast from '../context/withtoast';
import { getUsers } from '../instance/url';
import { getPage, getLimit ,pagination } from '../helper/helper';

const endPoint = getUsers();

const Users = ({ showErrorToast }) => {
	const navigate = useNavigate();
	const page = getPage(); 
	const limit = getLimit(); 
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(page);
  const [currentLimit, setCurrentLimit] = useState(limit);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchUsers = async (page, limit) => {
      try {
        const response = await axios.get(`${endPoint}?page=${page}&limit=${limit}`);
        if (response.status === 200) {
          console.log('users', response.data.data.values);
          setUsers(response.data.data.values);
          setTotalPages(response.data.data.total_pages);
        }
      } catch (error) {
        console.log(error);
        handleApiError(error, null, null, true, showErrorToast);
      }
    };
    fetchUsers(currentPage, currentLimit);
  }, [currentPage, currentLimit, showErrorToast]);

  const handlePageChange = (newPage, newLimit) => {
    if (newPage > 0 && newPage <= totalPages) {
			setCurrentLimit(newLimit);
      setCurrentPage(newPage);
			navigate(`?page=${newPage}&limit=${newLimit}`);
    }
  };

  return (
             <div className="body_section">
                <div className="card">
                  <div className="card-body">
                    <h4 className="heading_">Role Details</h4>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col" align="left">Name</th>
                          <th scope="col" align="left">Email</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((user, index) => (
                          <tr key={index}>
                            <td>{(currentPage - 1) * users.length + index + 1}</td>
                            <td align="left">{user.name}</td>
                            <td align="left">{user.email}</td>
                            <td>
                              <a href="" className='edit_icon'><FaPenToSquare /></a>
                              <a href="" className='fa-trash-can'><FaTrashCan /></a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="pagination">
                      <button
                        onClick={() => handlePageChange(currentPage - 1, getLimit())}
                        className="pagination-btn"
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      {pagination(currentPage, totalPages, handlePageChange)}
                      <button
                        onClick={() => handlePageChange(currentPage + 1, getLimit())}
                        className="pagination-btn"
                        disabled={currentPage === totalPages}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
						);
};

export default WithToast(Users);
