// ToastContext.js
import React, { createContext, useState, useContext } from 'react';
import { toast } from 'react-toastify';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [is422ErrorToastActive, setIs422ErrorToastActive] = useState(false);

  const showErrorToast = (errorMessage, toastId) => {
    if (!toast.isActive(toastId)) {
      setIs422ErrorToastActive(true); // Set the state indicating the error toast is active
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        toastId: toastId,
        onClose: () => setIs422ErrorToastActive(false) // Reset the state when the toast is closed
      });
    }
  };

  const showSuccessToast = (message,toastId) => {
    if (!toast.isActive(toastId)) {
      setIs422ErrorToastActive(true); 
    toast.success(message, {
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      backgroundColor: '#02CD8D',
      toastId: toastId,
      onClose: () => setIs422ErrorToastActive(false)
    });
  }
  };

  return (
    <ToastContext.Provider value={{ is422ErrorToastActive, showErrorToast, showSuccessToast }}>
      {children}
    </ToastContext.Provider>
  );
};

export const UseToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export default ToastContext;