import React, { useEffect, useState } from 'react';
import "../css/Body.css";
import { FaPenToSquare, FaTrashCan } from 'react-icons/fa6';
import axios from '../instance/axiosInstance';
import { handleApiError } from '../api/cloudfunction';
import WithToast from '../context/withtoast';
import { getRoles } from '../instance/url';

const rolesEndpoint = getRoles();

const Role = ({ showErrorToast }) => {
  const [rolesData, setRolesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchRoles = async (page) => {
      try {
        const response = await axios.get(`${rolesEndpoint}?page=${page}`);
        if (response.status === 200) {
          setRolesData(response.data.data.values);
          setTotalPages(response.data.data.total_pages);
        }
      } catch (error) {
        console.log(error);
        handleApiError(error, null, null, true, showErrorToast);
      }
    };
    fetchRoles(currentPage);
  }, [currentPage, showErrorToast]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const renderPagination = () => {
    const pages = [];
    const maxPageButtons = 5; 
    let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
    let endPage = Math.min(totalPages, startPage + maxPageButtons - 1);

    if (endPage - startPage + 1 < maxPageButtons) {
      startPage = Math.max(1, endPage - maxPageButtons + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`pagination-btn ${i === currentPage ? 'active' : ''}`}
        >
          {i}
        </button>
      );
    }

    return pages;
  };

  return (
             <div className="body_section">
                <div className="card">
                  <div className="card-body">
                    <h4 className="heading_">Role Details</h4>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col" align="left">Name</th>
                          <th scope="col">Popularity</th>
                          <th scope="col">Role</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rolesData.map((role, index) => (
                          <tr key={role.id}>
                            <td>{(currentPage - 1) * rolesData.length + index + 1}</td>
                            <td align="left">{role.name}</td>
                            <td>{role.popularity}</td>
                            <td>{role.role}</td>
                            <td>
                              <a href="" className='edit_icon'><FaPenToSquare /></a>
                              <a href="" className='fa-trash-can'><FaTrashCan /></a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="pagination">
                      <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        className="pagination-btn"
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      {renderPagination()}
                      <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        className="pagination-btn"
                        disabled={currentPage === totalPages}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
		 );
};

export default WithToast(Role);
