// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 991px) {
  .lu_namenubar ul {
    padding: 18px 8px !important;
  }
  .lu_namenubar ul li a {
    padding: 10px 10px !important;
    font-size: 12px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/css/Responsive.css"],"names":[],"mappings":"AAAA;EACE;IACE,4BAA4B;EAC9B;EACA;IACE,6BAA6B;IAC7B,0BAA0B;EAC5B;AACF","sourcesContent":["@media (max-width: 991px) {\n  .lu_namenubar ul {\n    padding: 18px 8px !important;\n  }\n  .lu_namenubar ul li a {\n    padding: 10px 10px !important;\n    font-size: 12px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
