// Header.jsx
import React, { useEffect } from 'react';
import "../css/Header.css";
import { FaAngleDown, FaBell } from 'react-icons/fa';
import { profile } from '../instance/url';
import { handleApiError } from '../api/cloudfunction';
import WithToast from '../context/withtoast';
const profileurl = profile();
// Use a URL or a local path for the image
// const userAvatar = 'https://via.placeholder.com/150';

const Header = ({showErrorToast}) => {
  return (
    <div className="header_section">
      <div className="row">
        <div className="col_2 col">
          <div className="search_input">
            <input type="text" className="form_control" placeholder="Search here..." />
            <i className="fa-solid fa-magnifying-glass"></i>
          </div>
        </div>
        <div className="col_2 col">
          <div className="txt_right d_flex align_center header_p justify_end">
            {/* <i className="fa-regular fa-bell bell_icon"></i> */}
            {/* <img
              src={userAvatar}
              alt="User Avatar"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithToast(Header);
