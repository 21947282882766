import React from 'react';
import { UseToast } from './toastcontext';

const WithToast = (Component) => {
  return (props) => {
    const { showErrorToast, showSuccessToast } = UseToast();
    return <Component {...props} showErrorToast={showErrorToast} showSuccessToast={showSuccessToast} />;
  };
};

export default WithToast;